import React from "react";
import Nav from "../Components/Error/Nav";

export default function Error() {
  return (
    <div>
      <Nav />
      Error 404
    </div>
  );
}
